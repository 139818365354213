<template>
    <div>
        <!-- 主体内容	 -->
        <el-card>
          <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/' }">
              <span class="nav_path"></span>首页
            </el-breadcrumb-item>
            <el-breadcrumb-item>提货单管理</el-breadcrumb-item>
            <el-breadcrumb-item>提货单列表</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="search_area">
            <div style="display: inline;">
              <el-input placeholder="输入车牌号" v-model="queryInfo.query" class="input-with-select" clearable
                        @clear="getPickList" style="width: 320px">
                <el-button slot="append" icon="el-icon-search" @click="getPickList"></el-button>
              </el-input>
            </div>
            <div style="display:inline-block;">
              <el-button type="primary" @click="addDialog" style="background-color: #42b7fb;border-color: #42b7fb;margin-left: 10px">添加提货单</el-button>
            </div>
          </div>

            <!-- 列表提货单内容区域	 -->
            <el-table :data="pickList" :header-cell-style="{background: '#F6F6F6',fontWeight: '600',color: '#333',}" style="width: 100%;border-top: 1px solid #f2f2f2">
                <el-table-column prop="pickId" label="id" min-width="50">
                </el-table-column>
              <el-table-column prop="pickWeighId" align="center" label="过磅单号" min-width="80">
              </el-table-column>
                <el-table-column prop="pickCar" align="center" label="车牌号" min-width="80">
                </el-table-column>
                <el-table-column prop="pickCompany" align="center" label="提货单位" min-width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="pickDriver" align="center" label="司机" min-width="80">
                </el-table-column>
                <el-table-column prop="pickGoods" align="center" label="货物" min-width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="pickCreated" align="center" label="创建时间" min-width="140" :formatter="TimeFormatter">
                </el-table-column>
                <el-table-column prop="pickUpdate" align="center" label="更新时间" min-width="140" :formatter="TimeFormatter">
                </el-table-column>
<!--                <el-table-column prop="pickEnd" align="center" label="完成时间" min-width="140" :formatter="TimeFormatter">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="pickNum" align="center" label="重量" min-width="80">-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="pickPrice" align="center" label="单价" min-width="80">-->
<!--                </el-table-column>-->
                <el-table-column prop="pickStatus" align="center" label="状态" min-width="80" :formatter="RoleFormatter">
                </el-table-column>
                <el-table-column prop="address" fixed="right" align="center" label="操作" min-width="120" style="text-align: center">
                    <template slot-scope="scope">
                        <el-button class="primary" type="text" @click="editPicklist(scope.row)">编辑</el-button>
                        <span class="separator">|</span>
                        <el-button class="primary" type="text" @click="removePicklist(scope.row.pickId)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页功能 -->
            <el-pagination align="left" @size-change="SizeChange" @current-change="CurrentChange"
                           :disabled="false"
                           :current-page="queryInfo.pagenum"
                           :page-sizes="[queryInfo.pagesize]" :page-size="queryInfo.pagesize"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>


        <!-- 添加提货单dialog对话框 -->

        <el-dialog align="left" title="添加提货单" :visible.sync="addListVisible" width="800px" @close="closeAddpcikDialog">
            <!--主体部分 -->
            <el-form :model="addPickForm" status-icon :rules="addPickFormRul" ref="addPickFormRel" label-width="100px"
                     class="demo-ruleForm">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="提货单位" prop="pickCompany">
                            <el-input v-model="addPickForm.pickCompany"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="提货人">
                            <el-input v-model="addPickForm.pickDriver"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="货物名称" prop="pickGoods">
                            <el-input v-model="addPickForm.pickGoods"></el-input>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="12">-->
<!--                        <el-form-item label="货物价格" prop="pickPrice">-->
<!--                            <el-input v-model="addPickForm.pickPrice"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col :span="10">-->
<!--                        <el-form-item label="货物数量" prop="pickNum">-->
<!--                            <el-input v-model="addPickForm.pickNum"><i slot="suffix"-->
<!--                                                                       style="font-style:normal;margin-right: 10px;">kg</i>-->
<!--                            </el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="车牌号码" prop="pickCar">
                            <div style="display: inline;">
                                <el-cascader v-model="addPickForm.carSelected" :options="options" style="max-width: 85px;"
                                             :show-all-levels="false" ref="cascader" @change="change"></el-cascader>
                            </div>
                            <span class="circle">.</span>
                            <div style="display: inline-block;">
                                <el-input v-model="addPickForm.pickCar_s" style="max-width: 180px"></el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <span slot="footer" class="dialog-footer">
				<el-button @click="addListVisible = false">取 消</el-button>
				<el-button class="submit_btn" type="primary" @click="addUserList">确 定</el-button>
			</span>
        </el-dialog>

        <!-- 修改提货单diaolog对话框 -->

        <el-dialog align="left" title="修改提货单" :visible.sync="editPickVisible" @close="closeEditDialog" width="800px">
            <!--主体部分 -->
            <el-form :model="editPickForm" status-icon :rules="addPickFormRul" ref="editPickFormRel" label-width="100px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="车牌号">
                            <el-input :disabled="true" v-model="editPickForm.pickCar" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="提货单位" prop="pickCompany">
                            <el-input v-model="editPickForm.pickCompany"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="提货人" prop="pickDriver">
                            <el-input v-model="editPickForm.pickDriver" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="货物名称" prop="pickGoods">
                            <el-input v-model="editPickForm.pickGoods"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-row>-->
<!--                    <el-col :span="10">-->
<!--                        <el-form-item label="货物价格" prop="pickPrice">-->
<!--                            <el-input v-model="editPickForm.pickPrice" autocomplete="off"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="12">-->
<!--                        <el-form-item label="货物数量" prop="pickNum">-->
<!--                            <el-input v-model.number="editPickForm.pickNum"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
            </el-form>

            <span slot="footer" class="dialog-footer">
      		<el-button @click="editPickVisible = false">取 消</el-button>
      		<el-button class="submit_btn" type="primary" @click="editPick">确 定</el-button>
      	</span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                //列表参数
                queryInfo: {
                    query: '',
                    pagenum: 1,
                    pagesize: 5
                },
                //提货单列表
                pickList: null,
                //总的数据条数
                total: 0,
                //添加用提货单户dialog,控制显示隐藏
                addListVisible: false,

                //添加提货单规则
                addPickFormRul: {
                  pickCar: [{
                        required: true,
                        message: '请输入车牌号',
                        trigger: 'blur'
                    },
                    // {
                    //     pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-Z0-9]{5}[挂学警港澳]?$)/,
                    //     message: '车牌号码有误',
                    //     trigger: 'change'
                    // }
                    ]
                },
                //修改提货单参数
                editPickForm: {
                    pickId: '',
                    pickWeighId: '',
                    pickCompany: '',
                    pickGoods: '',
                    pickDriver: '',
                    pickPrice: '',
                    pickNum: '',
                    pickCar: ''
                },
                //修改提货单dialog,控制显示隐藏
                editPickVisible: false,
                //添加提货单参数
                addPickForm: {
                    pickCompany: '',
                    pickGoods: '',
                    pickDriver: '',
                    pickPrice: '',
                    pickNum: '',
                    carSelected: '苏F',
                    pickCar_s: '',
                    pickCar: ''
                },
                //角色对话框
                editRoleVisible: false,
                id: '',
                rid: '',
                options: [{
                    value: '苏',
                    label: '江苏（苏）',
                    children: [{
                        value: '苏A',
                        label: '苏A'
                    }, {
                        value: '苏B',
                        label: '苏B'
                    }, {
                        value: '苏C',
                        label: '苏C'
                    }, {
                        value: '苏D',
                        label: '苏D'
                    }, {
                        value: '苏E',
                        label: '苏E'
                    }, {
                        value: '苏F',
                        label: '苏F',
                    }, {
                        value: '苏G',
                        label: '苏G'
                    }, {
                        value: '苏H',
                        label: '苏H'
                    }, {
                        value: '苏J',
                        label: '苏J'
                    }, {
                        value: '苏K',
                        label: '苏K'
                    }, {
                        value: '苏L',
                        label: '苏L'
                    }, {
                        value: '苏M',
                        label: '苏M'
                    }, {
                        value: '苏N',
                        label: '苏N'
                    }, {
                        value: '苏U',
                        label: '苏U'
                    }
                    ]
                },
                    {
                        value: '北京（京）',
                        label: '北京（京）',
                        children: [{
                            value: '京A',
                            label: '京A'
                        }, {
                            value: '京B',
                            label: '京B'
                        }, {
                            value: '京C',
                            label: '京C'
                        }, {
                            value: '京D',
                            label: '京D'
                        }, {
                            value: '京E',
                            label: '京E'
                        }, {
                            value: '京F',
                            label: '京F'
                        }, {
                            value: '京G',
                            label: '京G'
                        }, {
                            value: '京H',
                            label: '京H'
                        }, {
                            value: '京J',
                            label: '京J'
                        }, {
                            value: '京K',
                            label: '京K'
                        }, {
                            value: '京L',
                            label: '京L'
                        }, {
                            value: '京M',
                            label: '京M'
                        }, {
                            value: '京N',
                            label: '京N'
                        }, {
                            value: '京P',
                            label: '京P'
                        }, {
                            value: '京Q',
                            label: '京Q'
                        }, {
                            value: '京Y',
                            label: '京Y'
                        }
                        ]
                    }, {
                        value: '津',
                        label: '天津（津）',
                        children: [
                            {
                                value: '津A',
                                label: '津A'
                            }, {
                                value: '津B',
                                label: '津B'
                            }, {
                                value: '津C',
                                label: '津C'
                            }, {
                                value: '津D',
                                label: '津D'
                            }, {
                                value: '津E',
                                label: '津E'
                            }, {
                                value: '津F',
                                label: '津F'
                            }, {
                                value: '津G',
                                label: '津G'
                            }, {
                                value: '津H',
                                label: '津H'
                            }, {
                                value: '津J',
                                label: '津J'
                            }, {
                                value: '津K',
                                label: '津K'
                            }, {
                                value: '津L',
                                label: '津L'
                            }, {
                                value: '津M',
                                label: '津M'
                            }, {
                                value: '津N',
                                label: '津N'
                            }, {
                                value: '津P',
                                label: '津P'
                            }, {
                                value: '津Q',
                                label: '津Q'
                            }, {
                                value: '津R',
                                label: '津R'
                            }
                        ]
                    }
                    , {
                        value: '冀',
                        label: '河北（冀）',
                        children: [
                            {
                                value: '冀A',
                                label: '冀A'
                            }, {
                                value: '冀B',
                                label: '冀B'
                            }, {
                                value: '冀C',
                                label: '冀C'
                            }, {
                                value: '冀D',
                                label: '冀D'
                            }, {
                                value: '冀E',
                                label: '冀E'
                            }, {
                                value: '冀F',
                                label: '冀F'
                            }, {
                                value: '冀G',
                                label: '冀G'
                            }, {
                                value: '冀H',
                                label: '冀H'
                            }, {
                                value: '冀J',
                                label: '冀J'
                            }, {
                                value: '冀O',
                                label: '冀O'
                            }, {
                                value: '冀R',
                                label: '冀R'
                            }, {
                                value: '冀T',
                                label: '冀T'
                            }
                        ]
                    }
                    , {
                        value: '晋',
                        label: '山西（晋）',
                        children: [
                            {
                                value: '晋A',
                                label: '晋A'
                            }, {
                                value: '晋B',
                                label: '晋B'
                            }, {
                                value: '晋C',
                                label: '晋C'
                            }, {
                                value: '晋D',
                                label: '晋D'
                            }, {
                                value: '晋E',
                                label: '晋E'
                            }, {
                                value: '晋F',
                                label: '晋F'
                            }, {
                                value: '晋H',
                                label: '晋H'
                            }, {
                                value: '晋J',
                                label: '晋J'
                            }, {
                                value: '晋K',
                                label: '晋K'
                            }, {
                                value: '晋L',
                                label: '晋L'
                            }, {
                                value: '晋M',
                                label: '晋M'
                            }
                        ]
                    }
                    , {
                        value: '蒙',
                        label: '内蒙古（蒙）',
                        children: [
                            {
                                value: '蒙A',
                                label: '蒙A'
                            }, {
                                value: '蒙B',
                                label: '蒙B'
                            }, {
                                value: '蒙C',
                                label: '蒙C'
                            }, {
                                value: '蒙D',
                                label: '蒙D'
                            }, {
                                value: '蒙E',
                                label: '蒙E'
                            }, {
                                value: '蒙F',
                                label: '蒙F'
                            }, {
                                value: '蒙G',
                                label: '蒙G'
                            }, {
                                value: '蒙H',
                                label: '蒙H'
                            }, {
                                value: '蒙J',
                                label: '蒙J'
                            }, {
                                value: '蒙K',
                                label: '蒙K'
                            }, {
                                value: '蒙L',
                                label: '蒙L'
                            }, {
                                value: '蒙M',
                                label: '蒙M'
                            }
                        ]
                    }
                    , {
                        value: '辽',
                        label: '辽宁（辽）',
                        children: [
                            {
                                value: '辽A',
                                label: '辽A'
                            }, {
                                value: '辽B',
                                label: '辽B'
                            }, {
                                value: '辽C',
                                label: '辽C'
                            }, {
                                value: '辽D',
                                label: '辽D'
                            }, {
                                value: '辽E',
                                label: '辽E'
                            }, {
                                value: '辽F',
                                label: '辽F'
                            }, {
                                value: '辽G',
                                label: '辽G'
                            }, {
                                value: '辽H',
                                label: '辽H'
                            }, {
                                value: '辽J',
                                label: '辽J'
                            }, {
                                value: '辽K',
                                label: '辽K'
                            }, {
                                value: '辽L',
                                label: '辽L'
                            }, {
                                value: '辽M',
                                label: '辽M'
                            }, {
                                value: '辽N',
                                label: '辽N'
                            }, {
                                value: '辽P',
                                label: '辽P'
                            }
                        ]
                    }
                    , {
                        value: '吉',
                        label: '吉林（吉）',
                        children: [
                            {
                                value: '吉A',
                                label: '吉A'
                            }, {
                                value: '吉B',
                                label: '吉B'
                            }, {
                                value: '吉C',
                                label: '吉C'
                            }, {
                                value: '吉D',
                                label: '吉D'
                            }, {
                                value: '吉E',
                                label: '吉E'
                            }, {
                                value: '吉F',
                                label: '吉F'
                            }, {
                                value: '吉G',
                                label: '吉G'
                            }, {
                                value: '吉H',
                                label: '吉H'
                            }, {
                                value: '吉J',
                                label: '吉J'
                            }, {
                                value: '吉K',
                                label: '吉K'
                            }
                        ]
                    }
                    , {
                        value: '黑',
                        label: '黑龙江（黑）',
                        children: [
                            {
                                value: '黑A',
                                label: '黑A'
                            }, {
                                value: '黑B',
                                label: '黑B'
                            }, {
                                value: '黑C',
                                label: '黑C'
                            }, {
                                value: '黑D',
                                label: '黑D'
                            }, {
                                value: '黑E',
                                label: '黑E'
                            }, {
                                value: '黑F',
                                label: '黑F'
                            }, {
                                value: '黑G',
                                label: '黑G'
                            }, {
                                value: '黑H',
                                label: '黑H'
                            }, {
                                value: '黑J',
                                label: '黑J'
                            }, {
                                value: '黑K',
                                label: '黑K'
                            }, {
                                value: '黑L',
                                label: '黑L'
                            }, {
                                value: '黑M',
                                label: '黑M'
                            }, {
                                value: '黑N',
                                label: '黑N'
                            }, {
                                value: '黑P',
                                label: '黑P'
                            }, {
                                value: '黑R',
                                label: '黑R'
                            }
                        ]
                    }, {
                        value: '沪',
                        label: '上海（沪）',
                        children: [
                            {
                                value: '沪A',
                                label: '沪A'
                            }, {
                                value: '沪B',
                                label: '沪B'
                            }, {
                                value: '沪C',
                                label: '沪C'
                            }, {
                                value: '沪D',
                                label: '沪D'
                            }, {
                                value: '沪E',
                                label: '沪E'
                            }, {
                                value: '沪F',
                                label: '沪F'
                            }, {
                                value: '沪G',
                                label: '沪G'
                            }, {
                                value: '沪H',
                                label: '沪H'
                            }, {
                                value: '沪J',
                                label: '沪J'
                            }, {
                                value: '沪K',
                                label: '沪K'
                            }, {
                                value: '沪L',
                                label: '沪L'
                            }, {
                                value: '沪M',
                                label: '沪M'
                            }, {
                                value: '沪N',
                                label: '沪N'
                            }
                        ]
                    }, {
                        value: '浙',
                        label: '浙江（浙）',
                        children: [
                            {
                                value: '浙A',
                                label: '浙A'
                            }, {
                                value: '浙B',
                                label: '浙B'
                            }, {
                                value: '浙C',
                                label: '浙C'
                            }, {
                                value: '浙D',
                                label: '浙D'
                            }, {
                                value: '浙E',
                                label: '浙E'
                            }, {
                                value: '浙F',
                                label: '浙F'
                            }, {
                                value: '浙G',
                                label: '浙G'
                            }, {
                                value: '浙H',
                                label: '浙H'
                            }, {
                                value: '浙J',
                                label: '浙J'
                            }, {
                                value: '浙K',
                                label: '浙K'
                            }, {
                                value: '浙L',
                                label: '浙L'
                            }
                        ]
                    }
                    , {
                        value: '皖',
                        label: '安徽（皖）',
                        children: [
                            {
                                value: '皖A',
                                label: '皖A'
                            }, {
                                value: '皖B',
                                label: '皖B'
                            }, {
                                value: '皖C',
                                label: '皖C'
                            }, {
                                value: '皖D',
                                label: '皖D'
                            }, {
                                value: '皖E',
                                label: '皖E'
                            }, {
                                value: '皖F',
                                label: '皖F'
                            }, {
                                value: '皖G',
                                label: '皖G'
                            }, {
                                value: '皖H',
                                label: '皖H'
                            }, {
                                value: '皖J',
                                label: '皖J'
                            }, {
                                value: '皖K',
                                label: '皖K'
                            }, {
                                value: '皖L',
                                label: '皖L'
                            }, {
                                value: '皖M',
                                label: '皖M'
                            }, {
                                value: '皖N',
                                label: '皖N'
                            }, {
                                value: '皖P',
                                label: '皖P'
                            }, {
                                value: '皖R',
                                label: '皖R'
                            }, {
                                value: '皖S',
                                label: '皖S'
                            }
                        ]
                    }
                    , {
                        value: '闽',
                        label: '福建（闽）',
                        children: [
                            {
                                value: '闽A',
                                label: '闽A'
                            }, {
                                value: '闽B',
                                label: '闽B'
                            }, {
                                value: '闽C',
                                label: '闽C'
                            }, {
                                value: '闽D',
                                label: '闽D'
                            }, {
                                value: '闽E',
                                label: '闽E'
                            }, {
                                value: '闽F',
                                label: '闽F'
                            }, {
                                value: '闽G',
                                label: '闽G'
                            }, {
                                value: '闽H',
                                label: '闽H'
                            }, {
                                value: '闽J',
                                label: '闽J'
                            }, {
                                value: '闽K',
                                label: '闽K'
                            }
                        ]
                    }
                    , {
                        value: '赣',
                        label: '江西（赣）',
                        children: [
                            {
                                value: '赣A',
                                label: '赣A'
                            }, {
                                value: '赣B',
                                label: '赣B'
                            }, {
                                value: '赣C',
                                label: '赣C'
                            }, {
                                value: '赣D',
                                label: '赣D'
                            }, {
                                value: '赣E',
                                label: '赣E'
                            }, {
                                value: '赣F',
                                label: '赣F'
                            }, {
                                value: '赣G',
                                label: '赣G'
                            }, {
                                value: '赣H',
                                label: '赣H'
                            }, {
                                value: '赣J',
                                label: '赣J'
                            }, {
                                value: '赣K',
                                label: '赣K'
                            }, {
                                value: '赣L',
                                label: '赣L'
                            }, {
                                value: '赣M',
                                label: '赣M'
                            }, {
                                value: '赣S',
                                label: '赣S'
                            }
                        ]
                    }
                    , {
                        value: '鲁',
                        label: '山东（鲁）',
                        children: [
                            {
                                value: '鲁A',
                                label: '鲁A'
                            }, {
                                value: '鲁B',
                                label: '鲁B'
                            }, {
                                value: '鲁C',
                                label: '鲁C'
                            }, {
                                value: '鲁D',
                                label: '鲁D'
                            }, {
                                value: '鲁E',
                                label: '鲁E'
                            }, {
                                value: '鲁F',
                                label: '鲁F'
                            }, {
                                value: '鲁G',
                                label: '鲁G'
                            }, {
                                value: '鲁H',
                                label: '鲁H'
                            }, {
                                value: '鲁J',
                                label: '鲁J'
                            }, {
                                value: '鲁K',
                                label: '鲁K'
                            }, {
                                value: '鲁L',
                                label: '鲁L'
                            }, {
                                value: '鲁M',
                                label: '鲁M'
                            }, {
                                value: '鲁N',
                                label: '鲁N'
                            }, {
                                value: '鲁P',
                                label: '鲁P'
                            }, {
                                value: '鲁Q',
                                label: '鲁Q'
                            }, {
                                value: '鲁R',
                                label: '鲁R'
                            }, {
                                value: '鲁S',
                                label: '鲁S'
                            }, {
                                value: '鲁U',
                                label: '鲁U'
                            }, {
                                value: '鲁V',
                                label: '鲁V'
                            }, {
                                value: '鲁Y',
                                label: '鲁Y'
                            }
                        ]
                    }
                    , {
                        value: '豫',
                        label: '河南（豫）',
                        children: [
                            {
                                value: '豫A',
                                label: '豫A'
                            }, {
                                value: '豫B',
                                label: '豫B'
                            }, {
                                value: '豫C',
                                label: '豫C'
                            }, {
                                value: '豫D',
                                label: '豫D'
                            }, {
                                value: '豫E',
                                label: '豫E'
                            }, {
                                value: '豫F',
                                label: '豫F'
                            }, {
                                value: '豫G',
                                label: '豫G'
                            }, {
                                value: '豫H',
                                label: '豫H'
                            }, {
                                value: '豫J',
                                label: '豫J'
                            }, {
                                value: '豫K',
                                label: '豫K'
                            }, {
                                value: '豫L',
                                label: '豫L'
                            }, {
                                value: '豫M',
                                label: '豫M'
                            }, {
                                value: '豫N',
                                label: '豫N'
                            }, {
                                value: '豫P',
                                label: '豫P'
                            }, {
                                value: '豫Q',
                                label: '豫Q'
                            }, {
                                value: '豫R',
                                label: '豫R'
                            }, {
                                value: '豫S',
                                label: '豫S'
                            }, {
                                value: '豫U',
                                label: '豫U'
                            }, {
                                value: '豫V',
                                label: '豫V'
                            }
                        ]
                    }, {
                        value: '鄂',
                        label: '湖北（鄂）',
                        children: [
                            {
                                value: '鄂A',
                                label: '鄂A'
                            }, {
                                value: '鄂B',
                                label: '鄂B'
                            }, {
                                value: '鄂C',
                                label: '鄂C'
                            }, {
                                value: '鄂D',
                                label: '鄂D'
                            }, {
                                value: '鄂E',
                                label: '鄂E'
                            }, {
                                value: '鄂F',
                                label: '鄂F'
                            }, {
                                value: '鄂G',
                                label: '鄂G'
                            }, {
                                value: '鄂H',
                                label: '鄂H'
                            }, {
                                value: '鄂J',
                                label: '鄂J'
                            }, {
                                value: '鄂K',
                                label: '鄂K'
                            }, {
                                value: '鄂L',
                                label: '鄂L'
                            }, {
                                value: '鄂M',
                                label: '鄂M'
                            }, {
                                value: '鄂N',
                                label: '鄂N'
                            }, {
                                value: '鄂P',
                                label: '鄂P'
                            }, {
                                value: '鄂Q',
                                label: '鄂Q'
                            }, {
                                value: '鄂R',
                                label: '鄂R'
                            }, {
                                value: '鄂S',
                                label: '鄂S'
                            }
                        ]
                    }
                    , {
                        value: '湘',
                        label: '湖南（湘）',
                        children: [
                            {
                                value: '湘A',
                                label: '湘A'
                            }, {
                                value: '湘B',
                                label: '湘B'
                            }, {
                                value: '湘C',
                                label: '湘C'
                            }, {
                                value: '湘D',
                                label: '湘D'
                            }, {
                                value: '湘E',
                                label: '湘E'
                            }, {
                                value: '湘F',
                                label: '湘F'
                            }, {
                                value: '湘G',
                                label: '湘G'
                            }, {
                                value: '湘H',
                                label: '湘H'
                            }, {
                                value: '湘J',
                                label: '湘J'
                            }, {
                                value: '湘K',
                                label: '湘K'
                            }, {
                                value: '湘L',
                                label: '湘L'
                            }, {
                                value: '湘M',
                                label: '湘M'
                            }, {
                                value: '湘N',
                                label: '湘N'
                            }, {
                                value: '湘S',
                                label: '湘S'
                            }, {
                                value: '湘U',
                                label: '湘U'
                            }
                        ]
                    }
                    , {
                        value: '粤',
                        label: '广东（粤）',
                        children: [
                            {
                                value: '粤A',
                                label: '粤A'
                            }, {
                                value: '粤B',
                                label: '粤B'
                            }, {
                                value: '粤C',
                                label: '粤C'
                            }, {
                                value: '粤D',
                                label: '粤D'
                            }, {
                                value: '粤E',
                                label: '粤E'
                            }, {
                                value: '粤F',
                                label: '粤F'
                            }, {
                                value: '粤G',
                                label: '粤G'
                            }, {
                                value: '粤H',
                                label: '粤H'
                            }, {
                                value: '粤J',
                                label: '粤J'
                            }, {
                                value: '粤K',
                                label: '粤K'
                            }, {
                                value: '粤L',
                                label: '粤L'
                            }, {
                                value: '粤M',
                                label: '粤M'
                            }, {
                                value: '粤N',
                                label: '粤N'
                            }, {
                                value: '粤P',
                                label: '粤P'
                            }, {
                                value: '粤Q',
                                label: '粤Q'
                            }, {
                                value: '粤R',
                                label: '粤R'
                            }, {
                                value: '粤S',
                                label: '粤S'
                            }, {
                                value: '粤T',
                                label: '粤T'
                            }, {
                                value: '粤U',
                                label: '粤U'
                            }, {
                                value: '粤V',
                                label: '粤V'
                            }, {
                                value: '粤W',
                                label: '粤W'
                            }, {
                                value: '粤X',
                                label: '粤X'
                            }, {
                                value: '粤Y',
                                label: '粤Y'
                            }, {
                                value: '粤Z',
                                label: '粤Z'
                            }
                        ]
                    }, {
                        value: '桂',
                        label: '广西（桂）',
                        children: [
                            {
                                value: '桂A',
                                label: '桂A'
                            }, {
                                value: '桂B',
                                label: '桂B'
                            }, {
                                value: '桂C',
                                label: '桂C'
                            }, {
                                value: '桂D',
                                label: '桂D'
                            }, {
                                value: '桂E',
                                label: '桂E'
                            }, {
                                value: '桂F',
                                label: '桂F'
                            }, {
                                value: '桂G',
                                label: '桂G'
                            }, {
                                value: '桂H',
                                label: '桂H'
                            }, {
                                value: '桂J',
                                label: '桂J'
                            }, {
                                value: '桂K',
                                label: '桂K'
                            }, {
                                value: '桂L',
                                label: '桂L'
                            }, {
                                value: '桂M',
                                label: '桂M'
                            }, {
                                value: '桂N',
                                label: '桂N'
                            }, {
                                value: '桂P',
                                label: '桂P'
                            }, {
                                value: '桂R',
                                label: '桂R'
                            }
                        ]
                    }
                    , {
                        value: '琼',
                        label: '海南（琼）',
                        children: [
                            {
                                value: '琼A',
                                label: '琼A'
                            }, {
                                value: '琼B',
                                label: '琼B'
                            }, {
                                value: '琼C',
                                label: '琼C'
                            }, {
                                value: '琼D',
                                label: '琼D'
                            }
                        ]
                    }
                    , {
                        value: '渝',
                        label: '重庆（渝）',
                        children: [
                            {
                                value: '渝A',
                                label: '渝A'
                            }, {
                                value: '渝B',
                                label: '渝B'
                            }, {
                                value: '渝C',
                                label: '渝C'
                            }, {
                                value: '渝D',
                                label: '渝D'
                            }, {
                                value: '渝F',
                                label: '渝F'
                            }, {
                                value: '渝G',
                                label: '渝G'
                            }, {
                                value: '渝H',
                                label: '渝H'
                            }, {
                                value: '渝N',
                                label: '渝N'
                            }
                        ]
                    }
                    , {
                        value: '川',
                        label: '四川（川）',
                        children: [
                            {
                                value: '川A',
                                label: '川A'
                            }, {
                                value: '川B',
                                label: '川B'
                            }, {
                                value: '川C',
                                label: '川C'
                            }, {
                                value: '川D',
                                label: '川D'
                            }, {
                                value: '川E',
                                label: '川E'
                            }, {
                                value: '川F',
                                label: '川F'
                            }, {
                                value: '川H',
                                label: '川H'
                            }, {
                                value: '川J',
                                label: '川J'
                            }, {
                                value: '川K',
                                label: '川K'
                            }, {
                                value: '川L',
                                label: '川L'
                            }, {
                                value: '川M',
                                label: '川M'
                            }, {
                                value: '川Q',
                                label: '川Q'
                            }, {
                                value: '川R',
                                label: '川R'
                            }, {
                                value: '川S',
                                label: '川S'
                            }, {
                                value: '川T',
                                label: '川T'
                            }, {
                                value: '川U',
                                label: '川U'
                            }, {
                                value: '川V',
                                label: '川V'
                            }, {
                                value: '川W',
                                label: '川W'
                            }, {
                                value: '川X',
                                label: '川X'
                            }, {
                                value: '川Y',
                                label: '川Y'
                            }, {
                                value: '川Z',
                                label: '川Z'
                            }
                        ]
                    }
                    , {
                        value: '贵',
                        label: '贵州（贵）',
                        children: [
                            {
                                value: '贵A',
                                label: '贵A'
                            }, {
                                value: '贵B',
                                label: '贵B'
                            }, {
                                value: '贵C',
                                label: '贵C'
                            }, {
                                value: '贵D',
                                label: '贵D'
                            }, {
                                value: '贵E',
                                label: '贵E'
                            }, {
                                value: '贵F',
                                label: '贵F'
                            }, {
                                value: '贵G',
                                label: '贵G'
                            }, {
                                value: '贵H',
                                label: '贵H'
                            }, {
                                value: '贵J',
                                label: '贵J'
                            }
                        ]
                    }
                    , {
                        value: '云',
                        label: '云南（云）',
                        children: [
                            {
                                value: '云A',
                                label: '云A'
                            }, {
                                value: '云C',
                                label: '云C'
                            }, {
                                value: '云D',
                                label: '云D'
                            }, {
                                value: '云E',
                                label: '云E'
                            }, {
                                value: '云F',
                                label: '云F'
                            }, {
                                value: '云G',
                                label: '云G'
                            }, {
                                value: '云H',
                                label: '云H'
                            }, {
                                value: '云J',
                                label: '云J'
                            }, {
                                value: '云K',
                                label: '云K'
                            }, {
                                value: '云L',
                                label: '云L'
                            }, {
                                value: '云M',
                                label: '云M'
                            }, {
                                value: '云N',
                                label: '云N'
                            }, {
                                value: '云P',
                                label: '云P'
                            }, {
                                value: '云Q',
                                label: '云Q'
                            }, {
                                value: '云R',
                                label: '云R'
                            }, {
                                value: '云S',
                                label: '云S'
                            }
                        ]
                    }
                    , {
                        value: '藏',
                        label: '西藏（藏）',
                        children: [
                            {
                                value: '藏A',
                                label: '藏A'
                            }, {
                                value: '藏B',
                                label: '藏B'
                            }, {
                                value: '藏C',
                                label: '藏C'
                            }, {
                                value: '藏D',
                                label: '藏D'
                            }, {
                                value: '藏E',
                                label: '藏E'
                            }, {
                                value: '藏F',
                                label: '藏F'
                            }, {
                                value: '藏G',
                                label: '藏G'
                            }, {
                                value: '藏H',
                                label: '藏H'
                            }, {
                                value: '藏J',
                                label: '藏J'
                            }
                        ]
                    }
                    , {
                        value: '陕',
                        label: '陕西（陕）',
                        children: [
                            {
                                value: '陕A',
                                label: '陕A'
                            }, {
                                value: '陕B',
                                label: '陕B'
                            }, {
                                value: '陕C',
                                label: '陕C'
                            }, {
                                value: '陕D',
                                label: '陕D'
                            }, {
                                value: '陕E',
                                label: '陕E'
                            }, {
                                value: '陕F',
                                label: '陕F'
                            }, {
                                value: '陕G',
                                label: '陕G'
                            }, {
                                value: '陕H',
                                label: '陕H'
                            }, {
                                value: '陕J',
                                label: '陕J'
                            }, {
                                value: '陕K',
                                label: '陕K'
                            }, {
                                value: '陕U',
                                label: '陕U'
                            }, {
                                value: '陕V',
                                label: '陕V'
                            }
                        ]
                    }
                    , {
                        value: '甘',
                        label: '甘肃（甘）',
                        children: [
                            {
                                value: '甘A',
                                label: '甘A'
                            }, {
                                value: '甘B',
                                label: '甘B'
                            }, {
                                value: '甘C',
                                label: '甘C'
                            }, {
                                value: '甘D',
                                label: '甘D'
                            }, {
                                value: '甘E',
                                label: '甘E'
                            }, {
                                value: '甘F',
                                label: '甘F'
                            }, {
                                value: '甘G',
                                label: '甘G'
                            }, {
                                value: '甘H',
                                label: '甘H'
                            }, {
                                value: '甘J',
                                label: '甘J'
                            }, {
                                value: '甘K',
                                label: '甘K'
                            }, {
                                value: '甘L',
                                label: '甘L'
                            }, {
                                value: '甘M',
                                label: '甘M'
                            }, {
                                value: '甘N',
                                label: '甘N'
                            }, {
                                value: '甘P',
                                label: '甘P'
                            }
                        ]
                    }
                    , {
                        value: '青',
                        label: '青海（青）',
                        children: [
                            {
                                value: '青A',
                                label: '青A'
                            }, {
                                value: '青B',
                                label: '青B'
                            }, {
                                value: '青C',
                                label: '青C'
                            }, {
                                value: '青D',
                                label: '青D'
                            }, {
                                value: '青E',
                                label: '青E'
                            }, {
                                value: '青F',
                                label: '青F'
                            }, {
                                value: '青G',
                                label: '青G'
                            }, {
                                value: '青H',
                                label: '青H'
                            }
                        ]
                    }
                    , {
                        value: '宁',
                        label: '宁夏（宁）',
                        children: [
                            {
                                value: '宁A',
                                label: '宁A'
                            }, {
                                value: '宁B',
                                label: '宁B'
                            }, {
                                value: '宁C',
                                label: '宁C'
                            }, {
                                value: '宁D',
                                label: '宁D'
                            }, {
                                value: '宁E',
                                label: '宁E'
                            }
                        ]
                    }
                    , {
                        value: '新',
                        label: '新疆（新）',
                        children: [
                            {
                                value: '新A',
                                label: '新A'
                            }, {
                                value: '新B',
                                label: '新B'
                            }, {
                                value: '新C',
                                label: '新C'
                            }, {
                                value: '新D',
                                label: '新D'
                            }, {
                                value: '新E',
                                label: '新E'
                            }, {
                                value: '新F',
                                label: '新F'
                            }, {
                                value: '新G',
                                label: '新G'
                            }, {
                                value: '新H',
                                label: '新H'
                            }, {
                                value: '新J',
                                label: '新J'
                            }, {
                                value: '新K',
                                label: '新K'
                            }, {
                                value: '新L',
                                label: '新L'
                            }, {
                                value: '新M',
                                label: '新M'
                            }, {
                                value: '新N',
                                label: '新N'
                            }, {
                                value: '新P',
                                label: '新P'
                            }, {
                                value: '新Q',
                                label: '新Q'
                            }, {
                                value: '新R',
                                label: '新R'
                            }, {
                                value: '新S',
                                label: '新S'
                            }
                        ]
                    }
                ]
            }
        },
        mounted() {
            this.getPickList();
        },
        watch: {
            addPickForm: {
                handler(newVal) {
                    this.addPickForm.pickCar=newVal.carSelected+newVal.pickCar_s
                },
                deep: true
            }
        },
        methods: {
            //请求提货单列表
            getPickList() {
                this.$http.post('pick_query', this.queryInfo).then(res => {
                    if (res.data.code !== 200) return this.$message.error('查询失败！' + res.data.msg);
                    this.pickList = res.data.data.records;
                    this.total = res.data.data.total;
                })
            }
            ,
            //格式化时间
            TimeFormatter(row, column) {
              const moment = require('moment');
              const date = row[column.property];

              if (date === undefined) {
                return ''
              }

              date[1] = date[1] - 1
              return moment(date).format('yyyy-MM-DD HH:mm:ss')
            }

            ,
            RoleFormatter(row, column) {
                if (row.pickStatus == 0) {
                    return "称重流程"
                } else if (row.pickStatus == 1) {
                    return "称重完成"
                }
            }
            ,
            //每页数据条数改变时触发
            SizeChange(newval) {
                this.queryInfo.pagesize = newval;
                this.getPickList();
            }
            ,
            //当前页面改变时触发
            CurrentChange(newval) {
                this.queryInfo.pagenum = newval;
                this.getPickList();
            }
            ,
            //删除提货单
            removePicklist(id) {
              this.$confirm('删除提货单的同时，会删除对应的称重单，是否继续?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$http.post(`pick_delete/${id}`)
                    .then(res => {
                      //console.log(res);
                      if (res.data.code == 400) return this.$message.warning('不允许删除！');
                      if (res.data.code !== 200) return this.$message.error('删除失败！' + res.data.msg);

                      this.$message.success('删除成功！');

                      //刷新列表
                      this.getPickList();
                    })
              });
            }
            ,
            //点击添加提货单
            addDialog() {
                this.addListVisible = !this.addListVisible;
            }
            ,
            //点击确定发送添加提货单
            addUserList() {
                this.$refs.addPickFormRel.validate(valid => {
                    //校验规则全部正确向后台发送请求
                    if (!valid) return;
                    this.$http.post('pick_add', this.addPickForm).then(res => {
                        //console.log(res);
                        if (res.data.code !== 200) return this.$message.error("添加失败！" + res.data.msg);
                        this.$message.success('添加成功！');
                        //刷新列表
                        this.getPickList();
                        this.addListVisible = !this.addListVisible;
                    })
                })
            }
            ,
            //关闭添加提货单对话框，重置表单
            closeAddpcikDialog() {
                this.$refs.addPickFormRel.resetFields();
            },

            closeEditDialog() {
              this.$refs.editPickFormRel.resetFields();
            },
            //修改提货单信息事件
            editPicklist(item) {
                this.editPickVisible = !this.editPickVisible;
                this.editPickForm = {
                    pickId: item.pickId,
                    pickWeighId: item.pickWeighId,
                    pickCompany: item.pickCompany,
                    pickGoods: item.pickGoods,
                    pickDriver: item.pickDriver,
                    pickPrice: item.pickPrice,
                    pickNum: item.pickNum,
                    pickCar: item.pickCar
                }
            }
            ,
            //点击确定发送请求提货单信息
            editPick() {
              this.$refs.editPickFormRel.validate(valid => {
                //校验规则全部正确向后台发送请求
                if (!valid) return;
                this.$http.post('pick_update', this.editPickForm).then(res => {
                  //console.log(res);
                  if (res.data.code !== 200) return this.$message.error("修改失败！" + res.data.msg);
                  this.$message.success("修改成功！");
                  this.getPickList();
                  this.editPickVisible = !this.editPickVisible;
                })
              })
            }
            ,
            change() {
                const lenLength = this.$refs.cascader.getCheckedNodes()[0].pathLabels.length - 1
                this.addPickForm.carSelected = this.$refs.cascader.getCheckedNodes()[0].pathLabels[lenLength]
            }
        }
    }
</script>

<style scoped>
    .circle {
        /*text-align: center*/
      font-size: 24px;
      font-weight: bolder;
      margin: 0 3px;
    }
    .add_btn {

      font-weight: 500;
      color: #fff;
      background: #42B7FB;
      margin-left: 20px;
    }
    .add_btn:hover {
      color: #fff;
      background: #42B7FB;
    }
    .primary {
      font-size: 16px;

      font-weight: 500;
      color: #42B7FB;
    }
    .separator {
      color: #ccc;
      padding: 0 5px;
    }
    .search_area {
      float: right;
      margin-bottom: 20px;
      display: inline-block;
    }
    .el-breadcrumb {
      font-size: 14px;
      display: inline;
      float: left;
      margin: 15px 0 0 5px
    }
    /deep/.el-table .cell:empty::before {
      content: "—";
      color: #999;
    }
</style>
